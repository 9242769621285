<template>
  <el-dialog
  v-dialogDrag
    title="融资单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="auditCon">
      <p class="auditNote">审核意见：{{ form.note }}</p>
    </div>
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资单号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCode }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.commodityName }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">仓库名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.storageName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">持仓ID：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.warehousCode }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.commodityQuantity }}</p>
            </el-col>
          </el-row>
        </el-col>
       <el-col :span="12" v-for="(item,index) of form.specificationsName" :key="index">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.weight }}{{ form.commoditySpecifications }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">评估价值：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.offerAmount }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资放款金额：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeAmount }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">当前融资时长：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackDays }}天</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资利息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCurrentFee }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资逾期利息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCurrentLateFee }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeStatus | financeStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">审批时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.handleTime  | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款期限：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackLimit }}天</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">应还款时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackTime | DateFormateDay }} 前</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">已还款金额：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackAmount }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackStatus | paybackStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        warehousId: null,
        price: null
      }
    };
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row);
    },
    getDetails(id) {
      protocolFwd.param_findFinanceInfoDetailFront.param.financeId = id;
      http
        .postFront(protocolFwd.param_findFinanceInfoDetailFront)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.specificationsName = this.attributeTrans(value.specifications)
            this.form = value;
            for (let i in this.form) {
              if (this.form[i] === null) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
</style>